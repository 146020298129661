<template>
  <div v-if="showBulkInvoicingSelection">
    <ul class="tw-list-disc tw-ml-4 tw-mb-4">
      <li>Wil je de geselecteerde kost(en) individueel factureren?</li>
      <li>Wil je de openstaan kosten factureren voor alle panden die gelinkt zijn aan de eigenaar van dit pand (bulkfacturatie)?</li>
    </ul>
    <p class="tw-italic tw-mb-4">
      Bulkfacturatie is enkel van toepassing op het facturatiecontact van de eigenaar.
      In het geval van een gesplitste facturatie over meerdere eigenaars of over bijvoorbeeld de verkoper en de koper is bulkfacturatie niet mogelijk.
    </p>
    <button
      type="button"
      class="btn btn-success tw-mr-2"
      @click="selectIndividualInvoicing"
    >
      Individuele facturatie
    </button>
    <button
      type="button"
      class="btn btn-success"
      @click="selectBulkInvoicing"
    >
      Bulkfacturatie
    </button>
  </div>
  <div v-else-if="showThirdPartySelection">
    <div v-if="showThirdPartyInvoiceContactForm">
      <FormulateForm
        #default="{ isLoading }"
        v-model="thirdPartyContactValues"
        name="thirdPartyContact"
        class="md:tw-w-1/2"
        @submit="createOrUpdateThirdPartyContact"
      >
        <FormulateInput
          type="autocomplete"
          auto-complete-type="contact"
          name="contact"
          label="Contact"
          placeholder="Zoek op contact"
          validation="required"
          :disabled="editThirdPartyInvoiceContact"
          @input="setTitle"
        >
          <template #label="{ label, id, classes }">
            <div class="tw-flex tw-justify-between">
              <label
                :for="id"
                v-text="label"
                :class="classes.label"
                class="formulate-label"
              />
              <button
                type="button"
                class="link tw-font-semibold"
                @click="showContactCreateModal"
              >
                <i class="far fa-user-plus" /> Maak nieuw
              </button>
            </div>
          </template>
        </FormulateInput>
        <FormulateInput
          type="text"
          name="title"
          label="Facturatiecontacttitel"
          placeholder="Facturatiecontacttitel"
          validation="required"
        />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          class="tw-mt-8"
          >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          {{ editThirdPartyInvoiceContact ? 'Derde partij bewerken' : 'Derde partij aanmaken' }}
        </FormulateInput>
      </FormulateForm>

      <ContactCreateModal
        ref="contactCreateModal"
        :redirect="false"
        @success="newContactCreated"
      />
    </div>
    <FormulateForm
      v-else
      v-model="thirdPartyFormValues"
      #default="{ isLoading }"
      name="thirdPartyForm"
      @submit="submitThirdPartySelection"
    >
      <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-4">
        <label
          v-for="thirdParty in thirdPartyInvoiceContacts"
          :key="`${thirdParty.id}_${thirdParty.title}`"
          :for="`${thirdParty.id}_${thirdParty.title}`"
          type="radio"
          name="third_party_entity_invoice_recipient_grouping"
          :class="[
            'tw-w-full tw-mt-4 md:tw-mt-0 tw-px-4 tw-py-4 tw-cursor-pointer tw-shadow-card tw-rounded tw-m-0',
            {
              'tw-border tw-border-gray-400': thirdParty.id === parseInt(thirdPartyFormValues.third_party_entity_invoice_recipient_grouping)
            }
          ]"
        >
          <input
            v-model="thirdPartyFormValues.third_party_entity_invoice_recipient_grouping"
            :id="`${thirdParty.id}_${thirdParty.title}`"
            :value="thirdParty.id"
            type="radio"
            checked
            class="sr-only"
          />
          <div class="tw-flex tw-gap-x-2 tw-justify-between">
            <EntityInvoiceContactsDisplay :invoice-contact="thirdParty" />
            <div class="tw-flex tw-gap-x-3 tw-items-start">
              <button
                type="button"
                title="Bewerken"
                class="
                  tw-text-success
                  hover:tw-text-darken-success
                  disabled:tw-text-gray-cc disabled:tw-cursor-not-allowed
                "
                @click="showThirdPartyContactForm(thirdParty)"
              >
                <i class="fas fa-pencil" />
              </button>
              <button
                v-if="!thirdParty.has_invoices"
                type="button"
                title="Verwijderen"
                :disabled="deletingThirdPartyInvoiceContact"
                class="
                  tw-text-danger
                  hover:tw-text-darken-danger
                  disabled:tw-text-gray-cc disabled:tw-cursor-not-allowed
                "
                @click="deleteThirdPartyContact(thirdParty)"
              >
                <i :class="['fas', deletingThirdPartyInvoiceContact ? 'fa-spinner-third fa-spin' : 'fa-trash']" />
              </button>
            </div>
          </div>
        </label>
        <button
          type="button"
          title="Nieuwe derde partij"
          class="btn btn-default tw-mt-4 md:tw-mt-0 tw-w-full"
          @click="showThirdPartyContactForm()"
        >
          <i class="fa fa-plus tw-mr-1" /> Nieuwe derde partij
        </button>
      </div>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :input-class="['tw-px-2 tw-h-8 tw-text-sm tw-w-full']"
        :outer-class="['md:tw-float-right tw-mt-8 tw-mb-4']"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-file-invoice'
          ]"
        />
        Factuurvoorbeeld laden voor derde partij
      </FormulateInput>
    </FormulateForm>
  </div>
  <div v-else-if="showProformaGeneration">
    <FormulateForm
      #default="{ isLoading }"
      v-model="proformaValues"
      name="proformaForm"
      class="tw-w-1/2"
      @submit="generateProformaInvoices"
    >
      <FormulateInput
        type="select"
        name="language"
        label="Taal"
        placeholder="Selecteer taal"
        validation="required"
        :options="USER_LANG_OPTIONS"
      />
      <FormulateInput
        type="date"
        name="due_date"
        label="Vervaldatum"
        validation="required"
      />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          class="tw-mt-8"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-file-invoice'
            ]"
          />
          {{ preview.sales_documents.length > 1 ? 'Proformafacturen' : 'Proformafactuur' }} genereren
        </FormulateInput>
      </FormulateForm>
  </div>
  <div v-else-if="initializing" class="tw-flex tw-justify-center">
    <i
      class="fal fa-spinner-third fa-spin tw-text-3xl"
    />
  </div>
  <div v-else>
    <div v-show="step === 1 && !roles.length">
      Er zijn geen facturatiecontacten ingesteld. Gelieve deze in te stellen via het tabblad 'Algemeen' op de {{ this.entityType === 'property' ? 'pandfiche' : 'projectfiche' }}.
      <button
        type="button"
        @click="initThirdPartySelection"
        class="btn btn-default tw-mt-4"
      >
        Factureren naar derde partij
      </button>
    </div>
    <div v-show="showRoleSplitForms">
      <p class="tw-font-bold">Er zijn facturatiecontacten <u>met een verschillende rol (eigenaars/kopers/huurders).</u>
        <br>
        Maak hieronder per kost de verdeling voor de facturatie.
      </p>
      <div v-for="cost in costsForRoleSplit" :key="cost.id">
        <div v-show="!cost.entity_invoice_recipient_grouping" class="tw-my-6 tw-px-4 tw-py-4 tw-shadow-card tw-rounded tw-relative">
          <div class="tw-flex tw-justify-between">
            <h3 class="tw-mb-0">{{ cost.description }}</h3>
            <h4 class="tw-my-0">
              {{ currency(cost.amount) }}
            </h4>
          </div>
          <FormulateForm
            v-model="roleSplit[cost.id]"
            name="roleSplitForm"
            debounce
          >
            <div class="tw-flex tw-gap-x-2 tw-w-full">
              <!-- name is explicitly made a string because role is a number and vue formulate accepts only Strings -->
              <FormulateInput
                v-for="(role, index) in roles"
                :key="`${role}_${index}`"
                type="number"
                min="0"
                max="100"
                validation="between:-1,101"
                :validation-messages="{
                  between: 'Aandeel tussen 0 en 100'
                }"
                :name="`${role}`"
                :label="getRoleLabel(role)"
                :value="getValue(role)"
                :element-class="['tw-flex tw-items-center']"
                :input-class="['tw-text-sm tw-rounded-none tw-h-8 hide-number-input-arrows disabled:tw-bg-white']"
                class="tw-w-1/4"
                @input="calculateAdjacentRoleSplit($event, role, roleSplit[cost.id])"
              >
                <div slot="suffix" class="input-unit tw-h-8 tw-m-0">
                  <i class="far fa-percent" />
                </div>
              </FormulateInput>
            </div>
          </FormulateForm>
        </div>
      </div>
      <div class="tw-flex tw-justify-between tw-gap-x-4 tw-items-center">
        <button
          v-if="entityId && bulkInvoicingSelection !== 'bulk'"
          type="button"
          class="btn btn-default"
          @click="initThirdPartySelection"
        >
          Derde partij selecteren
        </button>
        <span v-if="!allRoleSplitsForCostsEqual100" class="tw-text-red-500 tw-font-bold">De som van de facturatieaandelen per kost is niet gelijk aan 100%.</span>
        <span v-if="previewError" class="tw-text-red-500 tw-font-bold">Er ging iets mis bij het laden van de preview.</span>
        <button
          type="button"
          :disabled="!allRoleSplitsForCostsEqual100 || confirmingStep1 || previewError"
          class="btn btn-success"
          @click="confirmStep1"
        >
          <i :class="['fas tw-mr-1', confirmingStep1 ? 'fa-spinner-third fa-spin' : 'fa-arrow-right']" />
          Naar factuurvoorbeeld
        </button>
      </div>

    </div>
    <div v-show="step === 2">
      <div v-if="preview.sales_documents && preview.sales_documents.length">
        <h4 class="tw-my-0">
          {{ preview.document_date }}
          <div class="md:tw-float-right">
            Dagboek: <span v-if="!editJournal">{{ preview.journal_code }}</span>
            <select v-if="editJournal" id="journals" name="journals" v-model="selectedJournalId">
              <option
                v-for="journal in journals"
                :key="journal.value"
                :value="journal.value"
              >
                {{ journal.label }}
              </option>
            </select>
            <button
              v-else-if="entityId && canEditJournal"
              type="button"
              title="Dagboek aanpassen"
              @click="enableJournalEditing"
            >
              <i class="tw-ml-1 fa fa-pencil" />
            </button>
            / Betalingstermijn: <span v-if="!editPaymentTerm">{{ preview.sales_documents[0].payment_term.label }}</span>
            <select v-if="editPaymentTerm" v-model="selectedPaymentTermId" id="paymentTerm" name="payment_terms">
              <option
                v-for="payment_term in paymentTerms"
                :key="payment_term.value"
                :value="payment_term.value"
              >
                {{ payment_term.label }}
              </option>
            </select>
            <button
              v-else-if="isPaymentTermEditable"
              type="button"
              title="Betalingstermijn aanpassen"
              @click="enablePaymentTermEditing"
            >
              <i class="tw-ml-1 fa fa-pencil" />
            </button>
          </div>
        </h4>

        <div v-for="(document, index) in preview.sales_documents" :key="index" class="tw-my-6 tw-px-4 tw-py-4 tw-shadow-card tw-rounded tw-relative">
          <div class="tw-grid tw-grid-cols-5 tw-mb-8">
            <div class="tw-col-span-5 md:tw-col-span-3">
              <h3 class="tw-my-0 tw-font-light">{{ document.title }}</h3>
            </div>

            <div class="tw-col-span-5 md:tw-col-span-2">
              <div class="">
                <div class="">
                  <div class="tw-font-semibold md:tw-text-right">
                    <span>{{ getPrimaryInvoiceContactAddress(document.invoice_recipient_grouping_contacts) }}</span>
                    <br>
                    <span>{{ getPrimaryInvoiceContactVat(document.invoice_recipient_grouping_contacts) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(line, lineIndex) in document.document_lines"
            :key="`line_${lineIndex}`"
            class="tw-py-1 tw-grid tw-grid-cols-5 tw-gap-x-2 tw-border-b"
          >
            <div class="tw-col-span-3">{{ line.description }}</div>
            <div class="tw-col-span-1 tw-text-right">
              <span v-if="!entityId || !canEditVatCode || isCreditNote">{{ line.vat_percentage }}</span>
              <select
                v-else
                v-model="vatConfig[line.cost_id]"
                id="vatCodes"
                name="vatCodes"
                @change="confirmStep1"
              >
                <option
                  v-for="vatCode in vatCodes"
                  :key="vatCode.id"
                  :value="vatCode.id"
                >
                  {{ vatCode.name }}
                </option>
              </select>
            </div>
            <div class="tw-col-span-1 tw-text-right">{{ currency(line.amount_excl_vat) }}</div>
          </div>
          <div class="tw-flex tw-flex-wrap tw-justify-end tw-mt-8">
            <div class="tw-flex tw-flex-wrap tw-justify-between md:tw-w-1/2">
              <div class="tw-flex tw-gap-x-4 tw-justify-between tw-w-full">
                <span>Maatstaf van heffing</span>
                <span class="tw-text-right">{{ currency(document.total_amount_excl_vat) }}</span>
              </div>
              <div class="tw-flex tw-gap-x-4 tw-justify-between tw-w-full">
                <span>Btw-bedrag</span>
                <span class="tw-text-right">{{ currency(document.total_amount_vat) }}</span>
              </div>
              <div class="tw-flex tw-gap-x-4 tw-justify-between tw-w-full tw-font-bold">
                <span>Totaal</span>
                <span class="tw-text-right">{{ currency(document.total_amount_incl_vat) }}</span>
              </div>
            </div>
          </div>
          <div :class="['tw--translate-y-1/2 tw-translate-x-1/2 tw-absolute tw-top-0',  proformaPdfInvoices.length ? 'tw-right-11' : 'tw-right-0']">
            <a
              v-if="pdfInvoices && pdfInvoices[document.invoice_recipient_grouping_id]"
              :href="pdfInvoices[document.invoice_recipient_grouping_id]"
              target="_blank"
              class="
                tw-px-1.5 tw-py-1 tw-border
                tw-text-success tw-border-success tw-rounded-full tw-shadow-card tw-bg-white
                hover:tw-bg-success hover:tw-text-white
              "
            >
              <i class="fas fa-download tw-text-base" />
            </a>
            <a
              v-if="getProformaInvoiceForEntityInvoiceRecipientGroupingId(document.entity_invoice_recipient_grouping_id)"
              :href="getProformaInvoiceForEntityInvoiceRecipientGroupingId(document.entity_invoice_recipient_grouping_id).url"
              target="_blank"
              class="
                tw-px-1.5 tw-py-1 tw-border
                tw-text-success tw-border-success tw-rounded-full tw-shadow-card tw-bg-white
                hover:tw-bg-success hover:tw-text-white
              "
            >
              <i class="fas fa-download tw-text-base" /> Proforma
            </a>
          </div>
          <span
            v-if="sentEmails && sentEmails[document.invoice_recipient_grouping_id]"
            class="tw-border tw-rounded tw-py-1 tw-px-2 tw-bg-primary tw-text-white tw-font-bold tw-text-xs"
          >
            Automatische e-mail verzonden
          </span>
        </div>

        <p
          v-if="entityId && documentType === 1 && !preview.is_final_bill && bulkInvoicingSelection !== 'bulk'"
          class="tw-italic tw-mb-4"
        >
          Toegekende commerciële tegemoetkomingen worden verrekend bij de eindafrekening.
        </p>
        <p
          v-else-if="preview.is_final_bill"
          class="tw-font-bold tw-mb-4"
        >
          {{ documentType === 1
            ? 'Opgelet: het ereloon wordt gefactureerd. Dit betreft een eindfacturatie waarbij alle toegekende, openstaande commerciële tegemoetkomingen automatisch verrekend worden.'
            : 'Opgelet: het ereloon wordt gecrediteerd. Reeds verrekende commerciële tegemoetkomingen worden hierbij ook opgenomen.'
          }}
        </p>

        <div v-if="!invoicingDone" class="tw-flex tw-justify-between tw-gap-x-2">
          <button
            v-if="hasStepOne"
            :disabled="creatingInvoice"
            class="formulate-button tw-mt-2 tw-bg-gray-400"
            @click="step = 1"
          >
            <i class="fas tw-mr-1 fa-arrow-left" />
            Terug naar stap 1
          </button>
          <button
            v-if="!entityId"
            :disabled="creatingInvoice"
            class="btn hover:tw-text-white tw-text-white tw-mt-2 tw-bg-gray-400"
            @click="hideManualInvoicingPreview"
          >
            <i class="fas tw-mr-1 fa-arrow-left" />
            Terug naar stap 1
          </button>
          <button
            v-if="entityId && !hasStepOne && documentType === 1 && bulkInvoicingSelection !== 'bulk'"
            type="button"
            :disabled="creatingInvoice"
            class="btn btn-default tw-mt-2"
            @click="initThirdPartySelection"
          >
            Derde partij selecteren
          </button>
          <div v-else />
          <div class="tw-flex tw-gap-x-2">
            <button
              v-if="documentType === 1 && bulkInvoicingSelection !== 'bulk' && entityId"
              :disabled="creatingInvoice"
              type="button"
              class="btn btn-default tw-mt-2"
              @click="showProformaForm"
            >
              {{ preview.sales_documents.length > 1 ? 'Proformafacturen' : 'Proformafactuur' }}
            </button>
            <button
              type="button"
              :disabled="creatingInvoice || (invoicingProgress && invoicingProgress.error_code)"
              class="btn btn-success tw-mt-2 tw-ml-auto"
              @click="startInvoice"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  creatingInvoice && (!invoicingProgress || (invoicingProgress && !invoicingProgress.error_code))? 'fa-spinner-third fa-spin' : 'fa-file-invoice'
                ]"
              />
              {{ preview.document_type === 1 ? 'Factureren' : 'Crediteren' }}
            </button>
          </div>
        </div>
        <EntityInvoiceProgress
          :invoicing="creatingInvoice"
          :document-type="documentType"
          :progress="invoicingProgress"
        />
      </div>
    </div>
    <span v-if="initError" class="tw-text-red-500 tw-font-bold">
      {{ initErrorMessage || 'Er is iets misgegaan bij het laden van de preview.' }}
    </span>
    <div v-if="contactValidations">
      <div v-if="contactValidations.contacts_incomplete_address.length" class="tw-mb-4">
        <p>
          {{ contactValidations.contacts_incomplete_address.length > 1 ? 'De volgende facturatiecontacten hebben geen volledig adres' : 'Het onderstaande facturatiecontact heeft geen volledig adres' }} (straat + huisnummer + gemeente).
          Gelieve eerst de adresgegevens aan te vullen op de contactfiche.
        </p>
        <ul class="tw-list-disc tw-ml-4">
          <li v-for="contact in contactValidations.contacts_incomplete_address" :key="contact.id" class="tw-font-bold">
            <router-link :to="{ name: 'ContactDetails', params: { id: contact.id } }" target="_blank">
              {{ contact.display_name }}
            </router-link>
          </li>
        </ul>
      </div>
      <div v-if="contactValidations.contacts_business_without_vat_number.length">
        <p>
          {{ contactValidations.contacts_business_without_vat_number.length > 1 ? 'De volgende facturatiecontacten zijn van het type bedrijf, maar hebben geen btw-nummer' : 'Het onderstaande facturatiecontact is een bedrijf, maar heeft geen btw-nummer' }}.
          Gelieve het btw-nummer aan te vullen op de contactfiche.
        </p>
        <ul class="tw-list-disc tw-ml-4">
          <li v-for="contact in contactValidations.contacts_business_without_vat_number" :key="contact.id" class="tw-font-bold">
            <router-link :to="{ name: 'ContactDetails', params: { id: contact.id } }" target="_blank">
              {{ contact.display_name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { currency, getContactAddress, poll, getFutureDate, USER_LANG_OPTIONS } from '@/utils/helpers'
import { errorModal, successModal, warningModal } from '@/modalMessages'

import ContactCreateModal from '@/components/contacts/ContactCreateModal'
import EntityInvoiceContactsDisplay from '@/components/properties/EntityInvoiceContactsDisplay'
import EntityInvoiceProgress from '@/components/properties/EntityInvoiceProgress'

import { getJournals } from '@/services/journals'
import { getJobStatus, getPaymentTerms } from '@/services/apiService'
import {
  createEntityInvoiceContact,
  loadBoCountInvoiceInformation,
  deleteEntityInvoiceContact,
  getInvoices,
  isBulkInvoicingPossible,
  updateInvoiceContact,
  getVatCodes,
  startManualInvoicing
} from '@/services/invoices'
import {
  startProjectInvoice,
  getProjectInvoiceContacts,
  storeProjectGutenborgFile,
  createProjectProformaInvoice,
  generateProjectProformaInvoices
} from '@/services/projects'
import {
  getPropertyConcept,
  getPropertyInvoiceContacts,
  getPropertyInvoicePreview,
  startPropertyInvoice,
  generatePropertyProformaInvoices,
  createPropertyProformaInvoice,
  storeGutenborgFile
} from '@/services/properties'
import { pollGutenborg } from '@/services/gutenborgService'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'EntityInvoicePreview',
  components: {
    ContactCreateModal,
    EntityInvoiceContactsDisplay,
    EntityInvoiceProgress
  },
  props: {
    entityType: {
      type: String,
      required: false
    },
    entityId: {
      type: [Number, String],
      required: false
    }
  },
  constants: {
    USER_LANG_OPTIONS
  },
  data () {
    return {
      initializing: false,
      confirmingStep1: false,
      roleSplit: {},
      previewError: null,
      contactValidations: null,
      step: null,
      hasStepOne: false,
      costsForRoleSplit: [],
      roles: [],
      values: {},
      preview: {},
      creatingInvoice: null,
      invoicingProgress: null,
      invoicingDone: null,
      pdfInvoices: {},
      sentEmails: null,
      proformaPdfInvoices: [],
      initError: false,
      initErrorMessage: null,
      paymentTerms: [],
      showThirdPartySelection: false,
      thirdPartyInvoiceContacts: [],
      thirdPartyFormValues: {},
      thirdPartyContactValues: {},
      createThirdPartyInvoiceContact: false,
      editThirdPartyInvoiceContact: false,
      deletingThirdPartyInvoiceContact: false,
      showThirdPartyInvoiceContactForm: false,
      proformaValues: {},
      showProformaGeneration: false,
      showBulkInvoicingSelection: false,
      bulkInvoicingSelection: null,
      dueDate: null,
      editJournal: false,
      editPaymentTerm: false,
      selectedJournalId: null,
      selectedPaymentTermId: null,
      journals: [],
      vatCodes: [],
      vatConfig: {},
      manualInvoicingData: null
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['collaborator']),
    ...mapGetters('properties', ['getPropertyById']),

    documentType () {
      return this.preview.document_type
    },
    isInvoice () {
      return this.documentType === 1
    },
    isCreditNote () {
      return this.documentType === 2
    },
    allRoleSplitsForCostsEqual100 () {
      let result = true
      for (const roleShareMap of Object.values(this.roleSplit)) {
        let res = 0
        for (const share of Object.values(roleShareMap)) {
          res += parseFloat(share)
        }
        if (res !== 100) {
          result = false
          break
        }
      }
      return result
    },
    isSuperUser () {
      return this.user.is_superuser
    },
    canEditJournal () {
      return this.isSuperUser || this.collaborator.hasPerm('CHANGE_JOURNAL')
    },
    property () {
      return this.entityType === 'property' ? this.getPropertyById(this.entityId) : null
    },
    canEditVatCode () {
      return this.isSuperUser || this.collaborator.hasPerm('CHANGE_VAT_CODE')
    },
    showRoleSplitForms () {
      return this.step === 1 && !this.contactValidations && this.roles.length > 1 && this.costsForRoleSplit
    },
    isPaymentTermEditable () {
      // One should be able to edit the payment term in case it's a rental property (transaction type 3)
      // and there's a rental management type linked to it that is not ("classic rental": 0). It should be any other
      // type which is basically "rental management" ("beheer" in Dutch)
      // Some background knowledge: upon cost creation for the commission the cost creation is set to 60 DAYS.
      // It should be possible to manually set this back to 8 DAYS.
      if (this.isCreditNote) return false
      return this.property && this.property.transaction_type === 3 && this.property.rental_management_type !== 0
    }
  },
  methods: {
    currency,
    getContactAddress,

    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    showForManualInvoicing (previewData, manualInvoicingData) {
      this.preview = previewData
      this.manualInvoicingData = manualInvoicingData
      this.step = 2
    },
    newContactCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${first_name} ${last_name}`
      this.$set(this.thirdPartyContactValues, 'contact', { display_name, ...contact })
      return contact
    },

    async init (data) {
      try {
        this.initializing = true
        this.values = data
        if (!this.bulkInvoicingSelection) {
          if (!this.property) {
            this.bulkInvoicingSelection = 'individual'
            this.showBulkInvoicingSelection = false
          }
          const response = await isBulkInvoicingPossible(this.property.id)
          if (response.data?.is_bulk_invoicing_possible) {
            this.showBulkInvoicingSelection = true
          } else {
            this.bulkInvoicingSelection = 'individual'
          }
        }
        await this.getInvoicePreviewStep1()
        const [paymentTermsResponse, journalsResponse, vatCodesResponse] = await Promise.all([getPaymentTerms(), getJournals(), getVatCodes()])
        this.paymentTerms = paymentTermsResponse.data?.results
        this.journals = journalsResponse.data?.results
        this.vatCodes = vatCodesResponse.data?.results
        return [journalsResponse, paymentTermsResponse, vatCodesResponse]
      } catch (error) {
        this.initError = true
        if (error?.response?.data.code === 'INVOICED_COST') {
          this.initErrorMessage = 'Geselecteerde kost is al gefactureerd.'
        }
        throw error
      } finally {
        // The very brief artifical loading state prevents a "flickering" screen
        await new Promise(resolve => setTimeout(resolve, 500))
        this.initializing = false
      }
    },
    getRoleSplitPayload () {
      const data = []
      for (const [cost_id, roleShareMap] of Object.entries(this.roleSplit)) {
        for (const [role, share] of Object.entries(roleShareMap)) {
          data.push(
            {
              cost_id,
              role,
              share
            }
          )
        }
      }
      return data
    },
    getRoleLabel (role) {
      const roleLabelMap = {
        1: 'Eigenaars',
        2: 'Kopers',
        3: 'Huurders'
      }
      return roleLabelMap[role]
    },
    getValue (role) {
      return role === 1 ? 100 : 0 // Owners should be invoiced in full by default
    },
    getInvoiceContactPrimary (contacts) {
      return contacts.find(function (contact) { return contact.primary })?.contact
    },
    getPrimaryInvoiceContactAddress (contacts) {
      const primaryContact = this.getInvoiceContactPrimary(contacts)
      const address = getContactAddress(primaryContact)
      return address
    },
    getPrimaryInvoiceContactVat (contacts) {
      const primaryContact = this.getInvoiceContactPrimary(contacts)
      const vat = primaryContact.vat
      return vat ? `${vat}` : null
    },
    setProgress (progress) {
      this.invoicingProgress = progress.meta
    },
    setTitle (value) {
      // Only set the title if there was no previous title set
      if (!this.thirdPartyContactValues.title) this.$set(this.thirdPartyContactValues, 'title', value.display_name)
    },
    showThirdPartyContactForm (thirdPartyInvoiceContact = null) {
      if (thirdPartyInvoiceContact) {
        const contact = thirdPartyInvoiceContact.contacts[0].contact
        const title = thirdPartyInvoiceContact.title
        this.$set(this.thirdPartyContactValues, 'contact', contact)
        this.$set(this.thirdPartyContactValues, 'title', title)
        this.editThirdPartyInvoiceContact = thirdPartyInvoiceContact
      } else {
        this.createThirdPartyInvoiceContact = true
      }
      this.showThirdPartyInvoiceContactForm = true
    },
    enableJournalEditing () {
      this.editJournal = true
    },
    enablePaymentTermEditing () {
      this.editPaymentTerm = true
    },
    calculateAdjacentRoleSplit (value, currentRole, formValues) {
      const adjacentValue = 100 - parseFloat(value || 0)
      const adjacentRoles = this.roles.filter(role => role !== currentRole)
      const splitValue = parseFloat(adjacentValue) / adjacentRoles.length
      adjacentRoles.forEach(role => {
        this.$set(formValues, role, splitValue)
      })
    },
    hideManualInvoicingPreview () {
      this.$emit('hidePreview')
    },

    async selectIndividualInvoicing () {
      this.bulkInvoicingSelection = 'individual'
      this.showBulkInvoicingSelection = false
      await this.init(this.values)
    },

    async selectBulkInvoicing () {
      this.bulkInvoicingSelection = 'bulk'
      this.showBulkInvoicingSelection = false
      await this.init(this.values)
    },

    getProformaInvoiceForEntityInvoiceRecipientGroupingId (entityInvoiceRecipientGroupingId) {
      return this.proformaPdfInvoices.find(
        pdfInvoice => parseInt(pdfInvoice.entityInvoiceRecipientGroupingId) === parseInt(entityInvoiceRecipientGroupingId)
      )
    },

    async showProformaForm () {
      let dueDate = getFutureDate(8) // Default due date is current date + 8 days (Dewaele's default payment term)
      if (this.entityType === 'property') {
        const response = await getPropertyConcept(this.entityId)
        if (response.data.deed_date) {
          dueDate = response.data.deed_date // If 'aktedatum' (deed date) is available, take this date
        }
      }
      this.$set(this.proformaValues, 'language', 'nl')
      this.$set(this.proformaValues, 'due_date', dueDate)
      // The original `roleSplit` gets unset upon opening the proforma form, hence set it on the proforma values
      this.$set(this.proformaValues, 'role_split', this.getRoleSplitPayload())
      this.showProformaGeneration = true
    },

    async processGeneratedProformaInvoice (entityInvoiceRecipientGroupingId, pollResult) {
      if (pollResult.url) {
        const storeResponse = this.entityType === 'property'
          ? await storeGutenborgFile(this.entityId, { url: pollResult.url, type: 'proforma_invoice' })
          : await storeProjectGutenborgFile(this.entityId, { url: pollResult.url, type: 'proforma_invoice' })
        const fileId = storeResponse?.data?.id
        this.entityType === 'property'
          ? await createPropertyProformaInvoice(this.entityId, { due_date: this.dueDate, property_file: fileId })
          : await createProjectProformaInvoice(this.entityId, { due_date: this.dueDate, project_file: fileId })
        return { entityInvoiceRecipientGroupingId: entityInvoiceRecipientGroupingId, url: pollResult.url }
      }
    },

    async processProformaInvoiceJobs (proformaInvoiceJobs) {
      const result = []
      for (const [entityInvoiceRecipientGroupingId, gutenborgJobId] of Object.entries(proformaInvoiceJobs)) {
        const pollResult = await poll(gutenborgJobId, pollGutenborg, 1000)
        const resultForGrouping = await this.processGeneratedProformaInvoice(entityInvoiceRecipientGroupingId, pollResult)
        if (resultForGrouping) {
          result.push(resultForGrouping)
        }
      }
      this.proformaPdfInvoices = result
    },

    async generateProformaInvoices (data) {
      try {
        this.dueDate = data.due_date
        const payload = data
        const { cost_ids } = this.values
        payload.third_party_entity_invoice_recipient_grouping_id = this.thirdPartyFormValues?.third_party_entity_invoice_recipient_grouping
        payload.cost_ids = cost_ids
        payload.vat_config = this.vatConfig
        const response = this.entityType === 'property'
          ? await generatePropertyProformaInvoices(this.entityId, payload)
          : await generateProjectProformaInvoices(this.entityId, payload)
        await this.processProformaInvoiceJobs(response.data.document_ids_map)
        successModal('Proformafactuur werd succesvol gegenereerd en opgeslagen.')
        this.showProformaGeneration = false
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het genereren van de proformafactuur.')
      }
    },

    async initThirdPartySelection () {
      const response = this.entityType === 'property'
        ? await getPropertyInvoiceContacts(this.entityId, { role: 4 })
        : await getProjectInvoiceContacts(this.entityId, { role: 4 })
      this.thirdPartyInvoiceContacts = response.data?.results
      this.$set(this.thirdPartyFormValues, 'third_party_entity_invoice_recipient_grouping', this.thirdPartyInvoiceContacts?.[0]?.id)
      this.showThirdPartySelection = true
      if (!this.thirdPartyInvoiceContacts.length) {
        this.createThirdPartyInvoiceContact = true
        this.showThirdPartyInvoiceContactForm = true
      }
      return response
    },

    async createEntityInvoiceContact (data) {
      const payload = {
        title: data.title,
        contacts: [data.contact.id],
        invoicing_share: 100,
        role: 4
      }
      this.entityType === 'property'
        ? payload.property = this.entityId
        : payload.project = this.entityId
      return await createEntityInvoiceContact(payload)
    },

    async createOrUpdateThirdPartyContact (data) {
      try {
        let response = null
        if (this.createThirdPartyInvoiceContact) {
          await this.createEntityInvoiceContact(data)
        } else {
          const payload = {
            title: data.title
          }
          response = await updateInvoiceContact(this.editThirdPartyInvoiceContact.invoice_recipient_grouping_id, payload)
        }
        this.editThirdPartyInvoiceContact = null
        this.createThirdPartyInvoiceContact = false
        this.showThirdPartyInvoiceContactForm = false
        await this.initThirdPartySelection()
        return response
      } catch (error) {
        console.error(error)
        let errorMessage = 'Er ging iets mis bij het opslaan van het facturatiecontact'
        if (error.response?.data?.code === 'ENTITY_INVOICE_RECIPIENT_GROUPING_EXISTS') {
          errorMessage = 'Contact is reeds als facturatiecontact gekoppeld.'
        }
        errorModal(errorMessage)
      }
    },

    async deleteThirdPartyContact (thirdParty) {
      this.deletingThirdPartyInvoiceContact = true
      const response = await deleteEntityInvoiceContact(thirdParty.id)
      this.deletingThirdPartyInvoiceContact = false
      await this.initThirdPartySelection()
      return response
    },

    async submitThirdPartySelection () {
      this.showThirdPartySelection = false
      const response = await this.getInvoicePreviewStep2()
      return response
    },

    async getInvoicePreviewStep1 () {
      const data = this.values
      data.step = 1
      data.is_bulk_invoicing = this.bulkInvoicingSelection === 'bulk'
      data.third_party_entity_invoice_recipient_grouping_id = this.thirdPartyFormValues?.third_party_entity_invoice_recipient_grouping
      const previewResponse = await getPropertyInvoicePreview(this.entityId, data)

      this.step = 1

      if (previewResponse.data.roles.length === 0) {
        this.roles = previewResponse.data.roles
        return
      }

      // If the document type is "credit note (2)" or there are no costs to do a role split, go to step 2 immediately
      if (previewResponse.data.document_type === 2 || previewResponse.data.roles.length === 1) {
        return await this.getInvoicePreviewStep2()
      }
      this.hasStepOne = true
      this.roles = previewResponse.data.roles
      this.costsForRoleSplit = previewResponse.data.costs_for_role_split
      return previewResponse
    },
    async getInvoicePreviewStep2 (roleSplit = null) {
      try {
        const data = this.values
        data.step = 2
        if (roleSplit) data.role_split = roleSplit
        data.third_party_entity_invoice_recipient_grouping_id = this.thirdPartyFormValues?.third_party_entity_invoice_recipient_grouping
        data.vat_config = this.vatConfig
        const previewResponse = await getPropertyInvoicePreview(this.entityId, data)

        if (previewResponse.data.contact_validations) {
          this.contactValidations = previewResponse.data.contact_validations
          return
        }

        previewResponse.data.sales_documents.forEach(
          document => {
            document.document_lines.forEach(
              line => {
                this.vatConfig[line.cost_id] = line.vat_code_id
              }
            )
          }
        )
        this.step = 2
        this.preview = previewResponse.data
        // This function is also called when a third party contact has been selected
        // It is very well possible that the journal was selected manually beforehand
        // In that case: _DO NOT_ override the selectedJournalId
        if (!this.selectedJournalId) {
          this.selectedJournalId = previewResponse.data.journal_id
        }
        // Same applies to the payment term
        if (!this.paymentTermId) {
          this.selectedPaymentTermId = previewResponse.data?.sales_documents[0]?.payment_term.value
        }
        return previewResponse
      } catch (error) {
        this.initError = true
        if (error?.response?.data.code === 'REQUIRES_ONE_OWNER_INVOICE_CONTACT') {
          this.initErrorMessage = 'Voor bulkfacturatie is het belangrijk dat er slechts één eigenaarsfacturatiecontact is.'
        }
        this.previewError = true
      }
    },
    async confirmStep1 () {
      try {
        this.confirmingStep1 = true
        this.$set(this.thirdPartyFormValues, 'third_party_entity_invoice_recipient_grouping', undefined)
        const payload = this.getRoleSplitPayload()
        const response = await this.getInvoicePreviewStep2(payload)
        return response
      } finally {
        this.confirmingStep1 = false
      }
    },

    async doStartInvoicingRequest () {
      let response = null
      if (!this.entityId) {
        response = await startManualInvoicing(this.manualInvoicingData)
      } else {
        const cost_ids = this.preview.cost_ids
        const journal_id = this.selectedJournalId

        const role_split = this.getRoleSplitPayload()

        // This needs some explanation
        // The initial analysis for the invoicing project took into account that we can have and override the payment term for each invoice contact
        // This was later deemed totally unnecessary
        // Fast forward to 2024-08-29 https://dewaele.atlassian.net/browse/DEW-10338: it must be possible to change the payment term on the fly
        // for the entire invoicing of the cost - not tied to any invoice contact - taking into account some conditions.
        // The payload sent to the back-end still expects a payment term id per invoice contact as BE development cannot keep up with and rework things as
        // business keeps popping new requirements out of nowhere.
        // Having the back-end implementation in place ensures that we will be able to move forward quickly
        // if it is decided that one should be able to override the payment term
        // for each invoice contact (no YAGNI, it's bound to happen eventually).
        // Just as a reminder: each "sales document" is tied to an invoice contact.
        const paymentTermId = this.selectedPaymentTermId
        const invoiceRecipientGroupingPaymentTermMap = {}
        this.preview.sales_documents.forEach(
          salesDocument => {
            invoiceRecipientGroupingPaymentTermMap[salesDocument.invoice_recipient_grouping_id] = paymentTermId
          }
        )
        const payload = {
          cost_ids,
          journal_id,
          invoice_recipient_grouping_payment_term_map: invoiceRecipientGroupingPaymentTermMap,
          role_split,
          third_party_entity_invoice_recipient_grouping_id: this.thirdPartyFormValues?.third_party_entity_invoice_recipient_grouping,
          is_bulk_invoicing: this.bulkInvoicingSelection === 'bulk',
          vat_config: this.vatConfig
        }

        response = this.entityType === 'property'
          ? await startPropertyInvoice(this.entityId, payload)
          : await startProjectInvoice(this.entityId, payload)
      }
      return response.data?.job_id
    },

    async handleInvoicingCompletion () {
      const ids = this.invoicingProgress.invoices_synced_ids
      const params = { ids }
      params[this.entityType] = this.entityId
      const invoicesResponse = await getInvoices({ params })
      for (let invoice of invoicesResponse.data.results) {
        if (invoice.invoice_messages?.length) {
          this.$set(this.sentEmails, invoice.invoice_recipient_grouping.id, invoice.invoice_messages)
        }
        // Sometimes the property file / project file is not available straight after invoicing due to an unknown bug
        // In that case: call the endpoint to re-fetch the details from external system
        if (!invoice.property_file && !invoice.project_file && !invoice.storage_key) {
          const response = await loadBoCountInvoiceInformation(invoice.id)
          invoice = response.data
        }
        this.$set(this.pdfInvoices, invoice.invoice_recipient_grouping.id, invoice.pdf_url)
      }
    },

    async startInvoice () {
      try {
        this.invoicingProgress = null
        this.creatingInvoice = true
        this.proformaPdfInvoices = []
        const jobId = await this.doStartInvoicingRequest()
        const jobResponse = await poll(jobId, getJobStatus, 1000, null, this.setProgress)
        await this.handleInvoicingCompletion()
        if (!jobResponse.meta.invoices_synced_ids.length) return
        successModal(`${this.documentType === 1 ? 'Facturen' : "Creditnota's"} succesvol geboekt in BoCount.`)
        this.$emit('invoicing-done')
        return jobResponse
      } catch (error) {
        console.error(error)
        if (error?.response?.status === 409) return warningModal('Er wordt op dit moment gefactureerd voor dit pand.')
        this.setProgress(error.cause)
      } finally {
        this.creatingInvoice = false
        this.invoicingDone = true
      }
    }
  }
}
</script>
